<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">Register Quality</v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-form v-model="valid" class="mt-3 mx-1">
                <v-col cols="12" class="d-flex align-center pb-3">
                    <div class="d-flex align-center">
                        <v-icon class="pr-2">mdi-database-outline</v-icon>
                        <p class="mb-0">Pending For Review: {{ pendingQty }}</p>
                    </div>
                </v-col>
                <v-col cols="12" class="pt-0 pb-1">
                    <v-text-field
                        v-model="releaseQty"
                        prepend-icon="mdi-check"
                        color="green"
                        label="Amount to release:"
                        type="number"
                        required
                        :rules="[maxValue()]"
                        class="ma-0"
                    />
                </v-col>
                <v-col cols="12" class="pt-0 pb-1">
                    <v-text-field
                        v-model="reprocessQty"
                        prepend-icon="mdi-sync"
                        color="orange"
                        label="amount to be reprocessed:"
                        type="number"
                        required
                        :rules="[maxValue()]"
                        class="ma-0"
                    />
                </v-col>
                <v-col cols="12" class="pt-0">
                    <v-text-field
                        v-model="rejectQty"
                        prepend-icon="mdi-close"
                        color="red"
                        label="amount to be rejected:"
                        type="number"
                        required
                        :rules="[maxValue()]"
                        class="ma-0"
                    />
                </v-col>
                <v-col cols="12" class="pt-0 ma-0" v-if="rejectQty">
                    <v-combobox
                        v-model="NCcode"
                        :items="ncCodes"
                        hide-details
                        prepend-icon="mdi-barcode"
                        hide-selected
                        label="Non-conformity code: "
                        required
                        flat
                        item-text="longName"
                        :rules="[rules.required]"
                        class="mt-n3"
                    />
                </v-col>
            </v-form>
        </v-card-text>
        <v-card-actions class="pb-3 pt-0">
            <v-btn text color="secondary" @click="close">
                Close
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" :disabled="!valid" @click="save">
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'
//import _ from 'lodash'

export default {
    name: 'QualityProcess',
    props: {
        item: {
            type: Object,
            required: true,
        },
        workOrder: {
            type: Object,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        valid: false,
        loading: false,
        releaseQty: undefined,
        reprocessQty: undefined,
        rejectQty: undefined,
        NCcode: undefined,
        pendingQty: 0,
        rules: {
            required: v => !!v || 'Required',
            positive: v => (v && v <= 0) || 'Only positive values',
        },
        ncCodes: [],
        qualityProcess: { name: 'Quality', id: 'quality' },
    }),
    async mounted() {
        const {
            data: { settings },
        } = await API.getSettings()
        const setting = settings.find(
            setting => setting.name.toLowerCase() == 'quality'
        )
        this.ncCodes = setting.ncCodes
        this.ncCodes.forEach(code => {
            code.longName = code.description
                ? `${code.code} - (${code.description})`
                : `${code.code}`
        })
        this.calculateValues()
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        close() {
            this.$emit('closeDialog')
        },
        calculateValues() {
            try {
                this.loading = true
                this.pendingQty = 0
                let lastProcessIndex = this.item.processes.length - 1
                if (this.workOrder.assemblyWorkOrder != undefined) {
                    lastProcessIndex =
                        this.item.processes.findIndex(
                            p => p == this.qualityProcess.id
                        ) - 1
                }
                if (lastProcessIndex > -1) {
                    const lastProductionProcess = this.item.processes[
                        lastProcessIndex
                    ]
                    if (this.item.production) {
                        const production = this.item.production.filter(
                            entry => entry.process.id == lastProductionProcess
                        )
                        if (production.length > 0) {
                            this.pendingQty += production.reduce(
                                (accumulator, entry) => accumulator + entry.qty,
                                0
                            )
                        }
                    }
                    if (this.item.quality) {
                        const rejected = this.item.quality.reduce(
                            (accumulator, entry) =>
                                accumulator + (entry.rejectQty || 0),
                            0
                        )
                        const released = this.item.quality.reduce(
                            (accumulator, entry) =>
                                accumulator + (entry.releaseQty || 0),
                            0
                        )
                        this.pendingQty -=
                            Number(released || 0) + Number(rejected || 0)
                    }
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async save() {
            try {
                this.loading = true
                let params = {
                    partNumberId: this.item.partNumberId,
                    workOrderId: this.workOrder.id,
                }

                if (this.releaseQty) {
                    params.releaseQty = Number(this.releaseQty)
                }
                if (this.reprocessQty) {
                    params.reprocessQty = Number(this.reprocessQty)
                }
                if (this.rejectQty) {
                    params.rejectQty = Number(this.rejectQty)
                    params.NCcode = this.NCcode.id
                }

                const items = await API.registerQuality(params)
                this.$emit('closeDialog', items)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        maxValue() {
            const total =
                Number(this.releaseQty || 0) +
                Number(this.reprocessQty || 0) +
                Number(this.rejectQty || 0)

            if (total > this.pendingQty) {
                return `The sum of all the quantities cannot be greater than ${this.pendingQty}.`
            } else {
                return true
            }
        },
    },
}
</script>

<style>
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
</style>
